import { Component } from "react";
import { navigate } from "gatsby";
import axios from 'axios';

export default class PrivateRoute extends Component {

  apiEndPoint = process.env.GATSBY_API_URL;

  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (this.props.path === '/admin-panel') {
      // TODO Uncomment this when development complete
      if (user === null || this.isObjectEmpty(user)) {
        navigate('/admin')
      } else {
        axios.get(`${this.apiEndPoint}/users/me`, {
          headers: { 'Authorization': `Bearer ${user.jwt}` }
        }).then(
          (response) => {
            if (
              response.data.id !== user.user.id ||
              response.data.username !== user.user.username ||
              response.data.email !== user.user.email
            ) {
              navigate('/admin')  
            }
            // Continue
          }
        ).catch(
          (err) => {
            navigate('/admin')
          }
        )
      }
    }
  }
    /*
    let noOnLoginPage = location.pathname !== `/app/login`
    if (!isLoggedIn() && noOnLoginPage) {
      navigate("/app/login")
      return null
    }
  }
  */
  render() {
    return null;
  }
  
}