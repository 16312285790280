import React, { Component } from 'react';
import axios from 'axios';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import DataTable from 'react-data-table-component';
import Spinner from './Spinner';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-notifications-component/dist/theme.css';

export default class AdminNews extends Component {

  client = new ApolloClient({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  mdEditor = null;

  mdParser = null;

  initialData = null;

  published = new Date();

  formData = {};

  image = null;

  initialLang = 'en';

  formDirty = false;

  valid = {};

  languageState = {
    en: null,
    nl: null
  };
  
  constructor(props) {
    super(props);
    this.mdParser = new MarkdownIt();
    this.state = {
      showSpinner: false,
      refresh: true,
      show: this.props.show,
      list: true,
      row: null,
      isAdd: true,
      checkHandler: true,
      current_lang: 'en',
      current_lang_list: 'en',
      published: new Date(),
      company_related_portfolio: [],
      image_preview: null,
      relatedCompanies: [],
      valid: {},
      columns: [
        {
          name: 'Action',
          cell: row => <div><span className="fa fa-edit admin-table-edit" onClick={() => this.handleEdit(row)}></span><span className="fa fa-trash admin-table-delete" onClick={() => this.handleDelete(row)}></span></div>,
          maxWidth: '40px'
        },
        {
          name: 'ID',
          selector: 'id',
          soratble: true
        },
        {
          name: 'Client',
          selector: 'client',
          cell: row => <div>{row.client && row.client !== '--' && this.state.relatedCompanies.find(c => c.id === row.client) ? this.state.relatedCompanies.find(c => c.id === row.client).client : ''}</div>,
          soratble: true
        },
        {
          name: 'Published',
          selector: 'published',
          soratble: true
        },
        {
          name: 'Active',
          cell: row => <div>{row.active ? 'True' : 'False'}</div>,
          soratble: true
        }
      ],
      data: null
    }
  }

  /**
   * Handles edit action on selected row.
   */
  handleEdit = row => {
    this.setState({ showSpinner: true, list: false, row: row, isAdd: false }, () => {
      this.getCompaniesDropdown();
      this.setState({showSpinner: false});
      this.languageState[this.initialLang] = row;
    });
  }

  /**
   * Handles delete action on selected row.
   */
  handleDelete = row => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (typeof window !== 'undefined') {
      if (window.confirm('Are you sure you want to delete this item?')) {
        axios.delete(`${this.apiEndPoint}/news${this.state.current_lang}s/${row.id}`, {
          headers: { 'Authorization': `Bearer ${user.jwt}` }
        }).then(
          (response) => {
            // console.log(response.data);
          }
        ).catch(
          (err) => {
            // console.error(err);
          }
        ).finally(
          () => {
            this.getAllNews()
          }
        )
      } else {

      }
    }
    // console.log('handle click', row);
  }

  /**
   * Checks if object is empty. 
   */
  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  
  /**
   * On prop receiving.
   */
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ show: props.show })
    if (props.show) {
    }
  }

  getCompanies() {
    return this.client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          portfolio${this.state.current_lang}s (
            where: {
              active: true
            }
          ) {
            client
            id
          }
        }
      `
    });
  }

  getCompaniesDropdown() {
    this.getCompanies().then(
      (response) => {
        this.setState({ company_related_portfolio: response.data[`portfolio${this.state.current_lang}s`]});
      }
    )
  }

  /**
   * On component update.
   */
  componentDidUpdate() {
    if (this.props.show) {
      // console.log('comp did update', this.state);
      if (this.state.show && this.state.data === null) {
        this.getAllNews();
      } else {
        if (this.state.row !== null) {
          document.getElementsByName('client')[0].value = this.state.row.client;
          document.getElementsByName('source')[0].value = this.state.row.source;
          document.getElementsByName('source_url')[0].value = this.state.row.source_url;
          document.getElementsByName('img_heading')[0].value = this.state.row.img_heading;
          document.getElementsByName('slug')[0].value = this.state.row.slug;
          document.getElementsByName('active')[0].checked = this.state.row.active;
        }

        if (this.state.current_lang !== this.initialLang) {
          if (this.formDirty) {
            if (window.confirm('Are you sure you want to discard changes?')) {
              this.resetForm();
            }
          } else {
            if (this.languageState[this.state.current_lang] === null) {
              this.resetForm();
            }
          }
        }
      }
    }
  }

  /**
   * Reset dirty form.
   */
  resetForm() {
    if (!this.state.list) {
      document.getElementsByName('client')[0].value = '';
      document.getElementsByName('source')[0].value = '';
      document.getElementsByName('source_url')[0].value = '';
      document.getElementsByName('img_heading')[0].value = '';
    }
    this.setState({ row: null });
    this.initialLang = this.state.current_lang;
    this.formDirty = false;
    this.formData = {};
  }

  /**
   * On component mount.
   */
  componentDidMount() {
    this.getAllNews(); 
  }

  /**
   * It gets all news.
   */
  async getAllNews() {
    this.setState({ spinnerShow: true });
    const user = JSON.parse(localStorage.getItem('user'));

    // console.log(user);
    if (user !== null && !this.isObjectEmpty(user)) {
      let relatedCompanies = [];

      try {
        const { data } = await this.getCompanies();
        relatedCompanies = data[`portfolio${this.state.current_lang}s`];
      } catch (e) {
        console.log('error', e);
      }
      
      this.setState({relatedCompanies});

      axios.get(`${this.apiEndPoint}/news${this.state.current_lang}s`, {
        headers: { 'Authorization': `Bearer ${user.jwt}` }
      }).then(
        (response) => {
          this.initialData = response.data;
          response.data.forEach(
            (element) => {
              if (element.published) {
                element.published = element.published.replace('T', ' ').split('.')[0];
                console.log(element.published);
              }
            }
          )
          this.setState({ data: response.data }, () => {
            this.setState({ spinnerShow: false });
          });
          // console.log(response);
        }
      ).catch(
        (err) => {
          // console.error(err);
        }
      ).finally(
        () => {

        }
      )
    }
  }

  /**
   * Return to list.
   */
  goBack = event => {
    this.resetForm();
    this.setState({ list: true, row: null, isAdd: true, image_preview: null, valid: {} });
    this.getAllNews();
    this.valid = {};
    this.languageState = {
      en: null,
      nl: null
    }
  }

  /**
   * Redirect to new record form.
   */
  addNews = event => {
    this.setState({ 
        list: false, 
        isAdd: true, 
        row: null, 
        image_preview: null 
      }, () => {
      this.getCompaniesDropdown();
    });
    this.languageState = {
      en: null,
      nl: null
    }
  }

  /**
   * Editor change detection. TODO check if possible to acces the scope with global variables.
   */
  handleEditorChange ({html, text}) {
    console.log('handleEditorChange', html, text);
  }

  /**
   * Save news.
   */
  saveNews(me) {
    me.formData.article_content = '';
    me.formData.article_content = me.mdEditor.getMdValue();
    if (this.validateForm()) {
      if (this.state.isAdd) {
        this.saveNewArticle(me);
      } else {
        this.editArticle(me);
      }
    }
  }

  editArticle(me) {
    me.setState({spinnerShow: true});
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null && !this.isObjectEmpty(user)) {
      axios.put(`${me.apiEndPoint}/news${this.state.current_lang}s/${me.state.row.id}`, me.formData, {
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          this.formDirty = false;
          if (me.image !== undefined && me.image instanceof File) {
            const form = new FormData();
            form.append('files', me.image);
            form.append('refId', response.data.id);
            form.append('ref', `news${this.state.current_lang}`);
            form.append('field', 'heading_image');

            axios.post(`${me.apiEndPoint}/upload/`, form, {
              headers: {
                'Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'multipart/form-data'
              }
            }).then(
              (response) => {
                me.image = null;
                // console.log(response);
                this.formDirty = false;
                this.props.store.addNotification({
                  message: 'Success',
                  title: 'News article sucessfully edited.',
                  type: 'success',
                  container: "top-right",
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
                this.setState({ 
                  list: true, 
                  isAdd: true,
                  row: null, 
                  spinnerShow: false 
                }, () => {
                  this.getAllNews();
                  this.languageState = {
                    en: null,
                    nl: null
                  }
                });
              }
            ).catch(
              (err) => {
                console.error(err);
              }
            ).finally(
              () => {

              }
            )

          } else {
            this.setState({ 
              list: true, 
              isAdd: true,
              row: null, 
              spinnerShow: false 
              }, () => {
                this.getAllNews();
                this.languageState = {
                  en: null,
                  nl: null
                }
              });
          }
          // console.log(response.data);
        }
      ).catch(
        (err) => {

        }
      ).finally(
        () => {
         
        }
      )
    }
  }

  saveNewArticle(me) {
    let enData = {};
    let nlData = {};
    me.setState({spinnerShow: true});
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null && !this.isObjectEmpty(user)) {
      axios.post(`${me.apiEndPoint}/news${this.state.current_lang}s`, me.formData, {
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          if (this.state.current_lang === 'en') {
            enData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          } else {
            nlData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          }
          if (me.image !== undefined && me.image instanceof File) {
            const form = new FormData();
            form.append('files', me.image);
            form.append('refId', response.data.id);
            form.append('ref', `news${this.state.current_lang}`);
            form.append('field', 'heading_image');

            axios.post(`${me.apiEndPoint}/upload/`, form, {
              headers: {
                'Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'multipart/form-data'
              }
            }).then(
              (response) => {
                if (this.state.current_lang === 'en') {
                  enData.heading_image = response.data;
                  this.languageState[this.state.current_lang].heading_image = response.data;
                } else {
                  nlData.heading_image = response.data;
                  this.languageState[this.state.current_lang].heading_image = response.data;
                }
                // console.log(response);
                this.formDirty = false;
                let otherLang = '';
                if (this.state.isAdd) {
                  if (this.state.current_lang === 'en') {
                    otherLang = 'nl';
                  } else {
                    otherLang = 'en';
                  }
                  axios.post(`${me.apiEndPoint}/news${otherLang}s`, me.formData, {
                    headers: {'Authorization': `Bearer ${user.jwt}`}
                  }).then(
                    (response) => {
                      if (this.state.current_lang === 'en') {
                        enData = response.data;
                        this.languageState[this.state.current_lang] = response.data;
                      } else {
                        nlData = response.data;
                        this.languageState[this.state.current_lang] = response.data;
                      }
                      if (me.image !== undefined && me.image instanceof File) {
                        const form = new FormData();
                        form.append('files', me.image);
                        form.append('refId', response.data.id);
                        form.append('ref', `news${otherLang}`);
                        form.append('field', 'heading_image');
            
                        axios.post(`${me.apiEndPoint}/upload/`, form, {
                          headers: {
                            'Authorization': `Bearer ${user.jwt}`,
                            'Content-Type': 'multipart/form-data'
                          }
                        }).then(
                          (response) => {
                            if (this.state.current_lang === 'en') {
                              enData.heading_image = response.data;
                              this.languageState[this.state.current_lang].heading_image = response.data;
                            } else {
                              nlData.heading_image = response.data;
                              this.languageState[this.state.current_lang].heading_image = response.data;
                            }
                            // console.log(response);
                            me.image = null;
                            this.formDirty = false;
                            this.setState({isAdd: false});
                            this.props.store.addNotification({
                              message: 'News article successfully saved.',
                              title: 'Success',
                              type: 'success',
                              container: "top-right",
                              dismiss: {
                                duration: 3000,
                                onScreen: true
                              }
                            });
                            this.setState({ 
                              list: true, 
                              isAdd: true,
                              row: null, 
                              spinnerShow: false 
                            }, () => {
                              this.getAllNews();
                              this.languageState = {
                                en: null,
                                nl: null
                              }
                            });
                          }
                        ).catch(
                          (err) => {
                            console.error(err);
                          }
                        ).finally(
                          () => {
            
                          }
                        )
            
                      } else {
                        this.props.store.addNotification({
                          message: 'News article successfully saved.',
                          title: 'Success',
                          type: 'success',
                          container: "top-right",
                          dismiss: {
                            duration: 3000,
                            onScreen: true
                          }
                        });
                        this.setState({ 
                          list: true,
                          isAdd: true,
                          row: null, 
                          spinnerShow: false 
                        }, () => {
                          this.getAllNews();
                          this.languageState = {
                            en: null,
                            nl: null
                          }
                        });
                      }
                      // console.log(response.data);
                    }
                  ).catch(
                    (err) => {
            
                    }
                  ).finally(
                    () => {
                      
                    }
                  )
                }
              }
            ).catch(
              (err) => {
                console.error(err);
              }
            ).finally(
              () => {

              }
            )

          } else {
            this.formDirty = false;
            let otherLang = '';
            if (this.state.isAdd) {
              if (this.state.current_lang === 'en') {
                otherLang = 'nl';
              } else {
                otherLang = 'en';
              }
              axios.post(`${me.apiEndPoint}/news${otherLang}s`, me.formData, {
                headers: {'Authorization': `Bearer ${user.jwt}`}
              }).then(
                (response) => {
                  if (this.state.current_lang === 'en') {
                    enData = response.data;
                    this.languageState[this.state.current_lang] = response.data;
                  } else {
                    nlData = response.data;
                    this.languageState[this.state.current_lang] = response.data;
                  }
                  if (me.image !== undefined && me.image instanceof File) {
                    const form = new FormData();
                    form.append('files', me.image);
                    form.append('refId', response.data.id);
                    form.append('ref', `news${otherLang}`);
                    form.append('field', 'heading_image');
        
                    axios.post(`${me.apiEndPoint}/upload/`, form, {
                      headers: {
                        'Authorization': `Bearer ${user.jwt}`,
                        'Content-Type': 'multipart/form-data'
                      }
                    }).then(
                      (response) => {
                        if (this.state.current_lang === 'en') {
                          enData.heading_image = response.data;
                          this.languageState[this.state.current_lang].heading_image = response.data;
                        } else {
                          nlData.heading_image = response.data;
                          this.languageState[this.state.current_lang].heading_image = response.data;
                        }
                        // console.log(response);
                        me.image = null;
                        this.formDirty = false;
                        this.setState({isAdd: false});
                        this.props.store.addNotification({
                          message: 'News article successfully saved.',
                          title: 'Success',
                          type: 'success',
                          container: "top-right",
                          dismiss: {
                            duration: 3000,
                            onScreen: true
                          }
                        });
                        this.setState({ 
                          list: true, 
                          row: null, 
                          isAdd: true,
                          spinnerShow: false 
                        }, () => {
                          this.getAllNews();
                          this.languageState = {
                            en: null,
                            nl: null
                          }
                        });
                      }
                    ).catch(
                      (err) => {
                        console.error(err);
                      }
                    ).finally(
                      () => {
        
                      }
                    )
        
                  } else {
                    this.props.store.addNotification({
                      message: 'News article successfully saved.',
                      title: 'Success',
                      type: 'success',
                      container: "top-right",
                      dismiss: {
                        duration: 3000,
                        onScreen: true
                      }
                    });
                    this.setState({ 
                      list: true,
                      isAdd: true,
                      row: null, 
                      spinnerShow: false 
                    }, () => {
                      this.getAllNews();
                      this.languageState = {
                        en: null,
                        nl: null
                      }
                    });
                  }
                  // console.log(response.data);
                }
              ).catch(
                (err) => {
        
                }
              ).finally(
                () => {
                  
                }
              )
            }
          }
          // console.log(response.data);
        }
      ).catch(
        (err) => {

        }
      ).finally(
        () => {
          
        }
      )
    }
  }

  /**
   * It handles date change
   */
  handleDateChange = date => {
    this.setState({ published: date });
    const newDate = date.toISOString().replace('T', ' ').replace('.000Z', '');
    this.formData.published = newDate;
    if (this.languageState[this.state.current_lang] !== null) {
      this.languageState[this.state.current_lang]['published'] = newDate;
    }
    this.formDirty = true;
  }

  /**
   * It handles changes on inputs and updates data object.
   */
  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    //const checked = target.checked;
    if (name === 'img_heading') {
      const slug = value.toLowerCase().replace(/ /g, '_').replace(/\-|\.|\,|\'|\(|\)|\"/g, '').replace(/__/g, '_');
      document.getElementsByName('slug')[0].value = slug;
      this.formData['slug'] = slug;
      if (this.languageState[this.state.current_lang] !== null) {
        this.languageState[this.state.current_lang]['slug'] = value;
      }
    }
    
    if (name === 'image') {
      // console.log(target);
    }
    if (name === 'active' && this.state.row) {
      let row = this.state.row;
      row.active = !this.state.row.active;
      this.setState({ row: row }, () => {
        
      })
    } else if (name === 'active' && !this.state.row) {
      this.setState({ checkHandler: !this.state.checkHandler }, () => {
        
      })
      // this.state.checkHandler = !this.state.checkHandler;
    }
    this.formData[name] = name === 'active' ? target.checked : value;
    if (this.languageState[this.state.current_lang] !== null) {
      this.languageState[this.state.current_lang][name] = name === 'active' ? target.checked : value;
    }
    this.formDirty = true;
  }

  /**
   * Image upload handler
   */
  uploadImage = event => {
    const target = event.target;
    const file = target.files[0];
    this.image = file;
    this.formDirty = true;
    if (!this.state.isAdd && this.state.row && this.state.row.heading_image) {
      const row = this.state.row;
      row.heading_image = null;
      this.setState({row: row});
    }
    var reader = new FileReader();
    reader.readAsDataURL(file)
    // console.log(reader, reader.result,file);
    reader.onloadend = (e) => {
      // console.log(e, this, e.target.result);
      this.setState({ image_preview: reader.result }, () => {
        
      })
    }
    
    // console.log(reader.readAsDataURL(file));
  }

  handleLanguageChange = event => {
    const target = event.target;
    this.setState({ current_lang: target.value, spinnerShow: true });
    // console.log(target.value, this.languageState);
    if (this.languageState[target.value] !== null) {
      this.setState({row: this.languageState[target.value]});
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!this.state.isAdd) {
        axios.get(`${this.apiEndPoint}/news${target.value}s?id=${this.state.row.id}`, 
          {
            headers: { 'Authorization': `Bearer ${user.jwt}` } 
          }
        ).then(
          (response) => {
            this.languageState[target.value] = response.data[0];
            this.languageState[target.value].published = this.languageState[target.value].published.replace('T', ' ').split('.')[0];
            // console.log('after req end', this.languageState, response);
            this.setState({row: this.languageState[target.value], published: this.languageState[target.value].published, spinnerShow: false});
          }
        ).catch(
          (err) => {
            // console.error(err);
          }
        ).finally(
          () => {

          }
        )
      }
    }
  }

  handleLanguageChangeList = event => {
    const target = event.target;
    const value = target.value;
    this.setState({ current_lang: value }, () => {
      this.getAllNews();
    });
  }

  validateForm() {
    // console.log('test');
    let formValid = true;
    for (const props in this.formData) {
      if (this.formData[props] === undefined ||  this.formData[props] === null || this.formData[props] === '') {
        this.state.valid[props] = false;
      } else {
        this.state.valid[props] = true;
      }
    }
    this.setState({ refresh: !this.state.refresh });
    for (const props in  this.state.valid) {
      // console.log(this.state.valid[props], props);
      if (!this.state.valid[props]) {
        formValid = false;
        break;
      }
    }
    // console.log(formValid);
    return formValid;
  }

  searchData = event => {
    const target = event.target;
    const value = target.value;
    let searchResult = [];
    if (this.initialData && this.initialData.length !== 0) {
      for (const item of this.initialData) {
        let pushThis = false;
        for (const prop in item) {
          if (prop === 'summary' || prop === 'client') {
            if (typeof item[prop] === 'string') {
              if (item[prop].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                pushThis = true;
              }
            }
          }
        }
        if (pushThis) {
          searchResult.push(item);
        }
      }
      this.setState({data: searchResult})
    } else {
      this.setState({data: this.initialData})
    }
  }

  render() {
    const { show, list, data, columns } = this.state
    if (!show) {
      return null;
    } else {
      return(
        <div>
          <Spinner show={this.state.showSpinner} />
          <div className="admin-content-subheader">
            <div className="admin-subheader-left">
              {list ? (
                    <span className="fa fa-plus-circle admin-add-btn" onClick={this.addNews}></span>
                ) : (
                    <span className="fa fa-caret-left admin-back-btn" onClick={this.goBack}><span className="admin-back-text">Back</span></span>
                )
              }
            </div>
            <div className="admin-subheader-right">
              {list ? (
                  <div>
                    <select className="lang-select" name="current_lang" onChange={this.handleLanguageChangeList}>
                      <option value="en" selected>
                        EN
                      </option>
                      <option value="nl">
                        NL
                      </option>
                    </select>
                    <input placeholder="Search" onChange={this.searchData}/>
                  </div>
                ) : (
                  <div>
                    <select className="lang-select" name="current_lang" onChange={this.handleLanguageChange}>
                      <option value="en" selected>
                        EN
                      </option>
                      <option value="nl">
                        NL
                      </option>
                    </select>
                    <button className="admin-save-button" onClick={() => this.saveNews(this)}>Save</button>
                  </div>
                )
              }
            </div>
          </div>
          <div className="admin-content-warpper">
            {list ? (
              <div className="content-list-wrapper custom-table">
                <DataTable
                  title="News"
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 275px)"
                />
              </div>
            ) : (
              <div className="content-form-wrapper">
                <form>
                  <div className="admin-form-section-3">
                    <div className="admin-input-wrapper">
                    <label className="required">Client</label>
                      <select name="client" onChange={this.handleInputChange}>
                        {
                          this.state.row ? (
                            this.state.row.client || this.state.row.client === '--' ? (
                              <option selected>--</option>
                            ):
                            <option>--</option>
                          ):
                          <option selected>--</option>
                        }
                        {
                          this.state.company_related_portfolio.map(
                            (item,index) => 
                              this.state.row ? (
                                this.state.row.client || this.state.row.client !== '--' ? (
                                  <option value={item.id} selected>{item.client}</option>
                                ): (
                                  <option value={item.id}>{item.client}</option>
                                )
                              ) : (
                                <option value={item.id}>{item.client}</option>
                              )
                          )
                        }
                      </select>

                      {/* <label className="required">Client</label>
                      <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Source</label>
                      <input name="source" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Source URL</label>
                      <input name="source_url" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label>Active</label>
                      <label className="switch">
                        <input type="checkbox" checked={this.state.isAdd ? this.state.checkHandler : (this.state.row ? this.state.row.active : this.state.checkHandler)} name="active" onChange={this.handleInputChange} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="admin-form-section-3">
                    <div className="admin-input-wrapper">
                      <label className="required">Published</label>
                      <DatePicker
                        selected={this.state.row && this.state.row.published !== null ? (new Date(this.state.row.published.replace(' ', 'T') + '.000Z')) : null}
                        name="published"
                        onChange={this.handleDateChange}
                      />
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Image Heading</label>
                      <input name="img_heading" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Slug</label>
                      <input name="slug" required onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Investment link</label>
                      <select name="company_related" onChange={this.handleInputChange}>
                        {
                          this.state.row ? (
                            !this.state.row.company_related || this.state.row.company_related === '--' ? (
                              <option selected>--</option>
                            ):
                            <option>--</option>
                          ):
                          <option selected>--</option>
                        }
                        {
                          this.state.company_related_portfolio.map(
                            (item,index) => 
                              this.state.row ? (
                                this.state.row.company_related && this.state.row.company_related !== '--' && item.id == this.state.row.company_related ? (
                                  <option value={item.id} selected>{item.client}</option>
                                ): (
                                  <option value={item.id}>{item.client}</option>
                                )
                              ) : (
                                <option value={item.id}>{item.client}</option>
                              )
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="admin-form-section-3">
                    <div className="admin-image-upload">
                      <label>Image</label>
                      <div className="image-preview-wrapper">
                        <img alt="" src={this.state.row !== null && (this.state.row.heading_image !== undefined && this.state.row.heading_image !== null) ? 
                                        (this.apiEndPoint + '/' + this.state.row.heading_image.url) : 
                                        (this.state.image_preview !== null ? this.state.image_preview : '')}></img>
                      </div>
                      <input className="file-input" name="image" type="file" onChange={this.uploadImage}/>
                    </div>
                  </div>
                  
                  <div className="admin-editor-wrapper">
                    <label className="required">Content</label>
                    <div>
                      <MdEditor
                        ref={node => this.mdEditor = node}
                        value={this.state.row !== null ? this.state.row.article_content : ''}
                        renderHTML={(text) => this.mdParser.render(text)}
                        onChange={this.handleEditorChange}
                        required
                      />
                    </div> 
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )
    }
  }
}