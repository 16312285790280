import React, { Component } from 'react';
import PrivateRoute from './../components/PrivateRoute';
import Layout from '../layouts/en';
import AdminNews from './../components/AdminNews';
import AdminJobs from './../components/AdminJobs';
import AdminPortfolio from './../components/AdminPortfolio';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
import logoBlue from '../assets/images/logo.png';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';

export default class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobs: false,
      showNews: false,
      showPortfolio: false
    }
  }

  sideItemSelection = {
    jobs: false,
    news: false,
    portfolio: false
  };

  sidebarHandler = event => {
    const target = event.target;
    const name = target.dataset.name;

    console.log(event, target);
    console.log(name);
    switch (name) {
      case 'jobs':
        this.setState({ 
          showJobs: true,
          showNews: false,
          showPortfolio: false
        });
        break;
      case 'news': 
        this.setState({ 
          showJobs: false,
          showNews: true,
          showPortfolio: false
        });
        break;
      case 'portfolio':
        this.setState({ 
          showJobs: false,
          showNews: false,
          showPortfolio: true
        });
        break;
      default:
        break;
    }
  }

  logout = event => {
    event.preventDefault();
    localStorage.removeItem('user');
    navigate('/admin');
  } 

  render() {
    const { showJobs, showNews, showPortfolio } = this.state;
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta http-equiv="x-ua-compatible" content="ie=edge"/>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
          <title>Admin Panel</title>
          <meta name="title" content="Admin Panel"/>
        </Helmet>
        <PrivateRoute component={AdminLogin} path="/admin-panel"/>
        <div className="admin-panel-wrapper">
        <div className="admin-header">
          <div className="company-logo">
            <Link to="/">
              <img src={logoBlue} alt="" />
            </Link>
          </div>
          <div className="company-logout-wrapper">
            <button onClick={this.logout}>
              Logout 
              <i className="fas fa-sign-out-alt"></i>    
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="admin-sidebar">
          <ul className="sidebar-items-wrapper">
            <li className={showJobs ? 'sidebar-item active' : 'sidebar-item'} data-name="jobs" onClick={this.sidebarHandler}>
              <i className="fa fa-building admin-sidebar-icon"></i>
              Jobs
            </li>
            <li className={showNews ? 'sidebar-item active' : 'sidebar-item'} data-name="news" onClick={this.sidebarHandler}>
              <i className="fa fa-newspaper admin-sidebar-icon"></i>
              News
            </li>
            <li className={showPortfolio ? 'sidebar-item active' : 'sidebar-item'} data-name="portfolio" onClick={this.sidebarHandler}>
              <i className="fa fa-newspaper admin-sidebar-icon"></i>
                Portfolio
            </li>
          </ul> 
        </div>
        <div className="admin-panel-content admin-container">
          <AdminJobs show={showJobs} store={store}/>
          <AdminNews show={showNews} store={store}/>
          <AdminPortfolio show={showPortfolio} store={store}/>
        </div>
        <div className="admin-footer">      
        </div>
      </div>
      </Layout>
    )
  }

}