import React, { Component } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from './Spinner';

export default class AdminJobs extends Component {

  apiEndPoint = process.env.GATSBY_API_URL;

  mdEditor = null;

  mdParser = null;

  published = new Date();

  formData = {};

  image = null;

  initialLang = 'en';

  formDirty = false;

  valid = {};

  languageState = {
    en: null,
    nl: null
  };
  
  constructor(props) {
    super(props);
    this.mdParser = new MarkdownIt();
    this.state = {
      refresh: true,
      show: this.props.show,
      list: true,
      row: null,
      isAdd: true,
      checkHandler: true,
      current_lang: 'en',
      spinnerShow: false,
      current_lang_list: 'en',
      published: new Date(),
      image_preview: null,
      valid: {},
      columns: [
        {
          name: 'Action',
          cell: row => <div><span className="fa fa-edit admin-table-edit" onClick={() => this.handleEdit(row)}></span><span className="fa fa-trash admin-table-delete" onClick={() => this.handleDelete(row)}></span></div>,
          maxWidth: '40px'
        },
        {
          name: 'ID',
          selector: 'id',
          soratble: true
        },
        {
          name: 'Title',
          selector: 'job_title',
          soratble: true
        },
        {
          name: 'City',
          selector: 'city',
          soratble: true
        },
        {
          name: 'Country',
          selector: 'country',
          soratble: true
        },
        {
          name: 'Active',
          cell: row => <div>{row.active ? 'True' : 'False'}</div>,
          soratble: true
        }
      ],
      data: []
    }

    this.renderHTML = this.renderHTML.bind(this)
  }

  handleEdit = row => {
    this.setState({ list: false, row: row, isAdd: false });
    this.languageState[this.initialLang] = row;
  }

  handleDelete = row => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (typeof window !== 'undefined') {
    if (window.confirm('Are you sure you want to delete this item?')) {
      axios.delete(`${this.apiEndPoint}/jobs${this.state.current_lang}s/${row.id}`, {
        headers: { 'Authorization': `Bearer ${user.jwt}` }
      }).then(
        (response) => {
          // console.log(response.data);
        }
      ).catch(
        (err) => {
          // console.error(err);
        }
      ).finally(
        () => {
          this.getAllJobs()
        }
      )
    } else {

    }
  }
    // console.log('handle click', row);
  }

  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  
  static getDerivedStateFromProps(props, state) {
    return {
      show: props.show
    }
    // this.setState({show: props.show})
    // if (props.show) {
      
    // }
  }

  componentDidUpdate() {
    if (this.props.show) {
      // console.log('comp did update', this.state);
      if (this.state.show && this.state.data === null) {
        this.getAllJobs();
      } else {
        if (this.state.row !== null) {
          for (const props in this.state.row) {
            if (props !== undefined) {
              if (document.getElementsByName(props)[0] !== undefined) {
                document.getElementsByName(props)[0].value = this.state.row[props];
              }
            }
          }
          // document.getElementsByName('client')[0].value = this.state.row.client !== undefined ? this.state.row.client : '';
          // document.getElementsByName('summary')[0].value = this.state.row.summary;
          // document.getElementsByName('source')[0].value = this.state.row.source;
          // document.getElementsByName('img_heading')[0].value = this.state.row.img_heading;
          // console.log(document.getElementsByName('client'));
        }
      }
    }
  }

  componentDidMount() {
    this.getAllJobs(); 
  }

  getAllJobs() {
    this.setState({ spinnerShow: true });
    const user = JSON.parse(localStorage.getItem('user'));
      if (user !== null && !this.isObjectEmpty(user)) {
        axios.get(`${this.apiEndPoint}/jobs${this.state.current_lang}s`, {
          headers: { 'Authorization': `Bearer ${user.jwt}` }
        }).then(
          (response) => {
            this.initialData = response.data;
            this.setState({ data: response.data, spinnerShow: false });
          }
        ).catch(
          (err) => {
            console.error("ERROR", err);
          }
        ).finally(
          () => {

          }
        )
      }
  }

  goBack = event => {
    this.setState({ 
      list: true, 
      row: null, 
      isAdd: true,
      image_preview: null });
    this.getAllJobs();
    this.languageState = {
      en: null,
      nl: null
    }
  }

  addJob = event => {
    this.setState({ 
      list: false, 
      isAdd: true, 
      image_preview: null, 
      row: null }, () => {
      
    });
    // console.log('test');
  }

  handleEditorChange ({html, text}) {    
    // console.log('handleEditorChange', html, text)
  }

  validateForm() {
    return true;
  }

  /**
   * Save news.
   */
  saveJob(me) {
    me.formData.job_text = '';
    me.formData.job_text = me.mdEditor.getMdValue();
    if (me.formData.category === undefined) {
      me.formData.category = document.getElementsByName('category')[0].value;
    }
    if (me.formData.job_type === undefined) {
      me.formData.job_type = document.getElementsByName('job_type')[0].value;
    }
    if (this.validateForm()) {
      if (this.state.isAdd) {
        this.saveNewJob(me);
      } else {
        this.editJob(me);
      }
    }
  }

  editJob(me) {
    this.setState({ spinnerShow: true })
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null && !this.isObjectEmpty(user)) {
      axios.put(`${me.apiEndPoint}/jobs${this.state.current_lang}s/${me.state.row.id}`, me.formData, {
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          this.formDirty = false;
          if (me.image !== undefined && me.image instanceof File) {
            const form = new FormData();
            form.append('files', me.image);
            form.append('refId', response.data.id);
            form.append('ref', `jobs${this.state.current_lang}`);
            form.append('field', 'testimony_image');

            axios.post(`${me.apiEndPoint}/upload/`, form, {
              headers: {
                'Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'multipart/form-data'
              }
            }).then(
              (response) => {
                me.image = null;
                // console.log(response);
                this.formDirty = false;
                this.props.store.addNotification({
                  message: 'Success',
                  title: 'Job sucessfully edited.',
                  type: 'success',
                  container: "top-right",
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
                this.setState({ 
                  list: true, 
                  row: null, 
                  spinnerShow: false,
                  image_preview: null
                }, () => {
                  this.getAllJobs();
                  this.languageState = {
                    en: null,
                    nl: null
                  }
                });
              }
            ).catch(
              (err) => {
                console.error(err);
              }
            ).finally(
              () => {

              }
            )

          } else {
            this.props.store.addNotification({
              message: 'Success',
              title: 'Job sucessfully edited.',
              type: 'success',
              container: "top-right",
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.setState({ 
              list: true,
              row: null,
              spinnerShow: false,
              image_preview: null
             }, () => {
              this.getAllJobs();
              this.languageState = {
                en: null,
                nl: null
              }
            });
          }
          // console.log(response.data);
        }
      ).catch(
        (err) => {

        }
      ).finally(
        () => {
         
        }
      )
    }
  }

  saveNewJob(me) {
    let enData = {};
    let nlData = {};
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({spinnerShow: true});
    if (user !== null && !this.isObjectEmpty(user)) {
      axios.post(`${me.apiEndPoint}/jobs${this.state.current_lang}s`, me.formData, {
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          if (this.state.current_lang === 'en') {
            enData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          } else {
            nlData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          }
          if (me.image !== undefined && me.image instanceof File) {
            const form = new FormData();
            form.append('files', me.image);
            form.append('refId', response.data.id);
            form.append('ref', `jobs${this.state.current_lang}`);
            form.append('field', 'testimony_image');

            axios.post(`${me.apiEndPoint}/upload/`, form, {
              headers: {
                'Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'multipart/form-data'
              }
            }).then(
              (response) => {
                if (this.state.current_lang === 'en') {
                  enData.heading_image = response.data;
                  this.languageState[this.state.current_lang].heading_image = response.data;
                } else {
                  nlData.heading_image = response.data;
                  this.languageState[this.state.current_lang].heading_image = response.data;
                }
                // console.log(response);
                this.formDirty = false;
                let otherLang = '';
                if (this.state.isAdd) {
                  if (this.state.current_lang === 'en') {
                    otherLang = 'nl';
                  } else {
                    otherLang = 'en';
                  }
                  axios.post(`${me.apiEndPoint}/jobs${otherLang}s`, me.formData, {
                    headers: {'Authorization': `Bearer ${user.jwt}`}
                  }).then(
                    (response) => {
                      if (this.state.current_lang === 'en') {
                        enData = response.data;
                        this.languageState[this.state.current_lang] = response.data;
                      } else {
                        nlData = response.data;
                        this.languageState[this.state.current_lang] = response.data;
                      }
                      if (me.image !== undefined && me.image instanceof File) {
                        const form = new FormData();
                        form.append('files', me.image);
                        form.append('refId', response.data.id);
                        form.append('ref', `jobs${otherLang}`);
                        form.append('field', 'testimony_image');
            
                        axios.post(`${me.apiEndPoint}/upload/`, form, {
                          headers: {
                            'Authorization': `Bearer ${user.jwt}`,
                            'Content-Type': 'multipart/form-data'
                          }
                        }).then(
                          (response) => {
                            if (this.state.current_lang === 'en') {
                              enData.heading_image = response.data;
                              this.languageState[this.state.current_lang].heading_image = response.data;
                            } else {
                              nlData.heading_image = response.data;
                              this.languageState[this.state.current_lang].heading_image = response.data;
                            }
                            // console.log(response);
                            me.image = null;
                            this.formDirty = false;
                            this.props.store.addNotification({
                              message: 'Job sucessfully saved.',
                              title: 'Success',
                              type: 'success',
                              container: "top-right",
                              dismiss: {
                                duration: 3000,
                                onScreen: true
                              }
                            });
                            this.setState({
                              isAdd: false, 
                              list: true, 
                              row: null,
                              spinnerShow: false,
                              image_preview: null
                            }, () => {
                                this.getAllJobs();
                                this.languageState = {
                                  en: null,
                                  nl: null
                                }
                              });
                          }
                        ).catch(
                          (err) => {
                            console.error(err);
                          }
                        ).finally(
                          () => {
            
                          }
                        )
            
                      } else {
                        this.props.store.addNotification({
                          message: 'Job sucessfully saved.',
                          title: 'Success',
                          type: 'success',
                          container: "top-right",
                          dismiss: {
                            duration: 3000,
                            onScreen: true
                          }
                        });
                        this.setState({
                          isAdd: false, 
                          list: true, 
                          row: null,
                          spinnerShow: false,
                          image_preview: null
                        }, () => {
                          this.getAllJobs();
                          this.languageState = {
                            en: null,
                            nl: null
                          }
                        });
                      }
                      // console.log(response.data);
                    }
                  ).catch(
                    (err) => {
            
                    }
                  ).finally(
                    () => {
                      
                    }
                  )
                } else {
                  this.setState({
                    isAdd: false, 
                    list: true, 
                    row: null,
                    spinnerShow: false,
                    image_preview: null
                  }, () => {
                    this.getAllJobs();
                    this.languageState = {
                      en: null,
                      nl: null
                    }
                  });
                }
              }
            ).catch(
              (err) => {
                console.error(err);
              }
            ).finally(
              () => {

              }
            )

          } else {
            this.formDirty = false;
            let otherLang = '';
            if (this.state.isAdd) {
              if (this.state.current_lang === 'en') {
                otherLang = 'nl';
              } else {
                otherLang = 'en';
              }
              axios.post(`${me.apiEndPoint}/jobs${otherLang}s`, me.formData, {
                headers: {'Authorization': `Bearer ${user.jwt}`}
              }).then(
                (response) => {
                  if (this.state.current_lang === 'en') {
                    enData = response.data;
                    this.languageState[this.state.current_lang] = response.data;
                  } else {
                    nlData = response.data;
                    this.languageState[this.state.current_lang] = response.data;
                  }
                  if (me.image !== undefined && me.image instanceof File) {
                    const form = new FormData();
                    form.append('files', me.image);
                    form.append('refId', response.data.id);
                    form.append('ref', `jobs${otherLang}`);
                    form.append('field', 'testimony_image');
        
                    axios.post(`${me.apiEndPoint}/upload/`, form, {
                      headers: {
                        'Authorization': `Bearer ${user.jwt}`,
                        'Content-Type': 'multipart/form-data'
                      }
                    }).then(
                      (response) => {
                        if (this.state.current_lang === 'en') {
                          enData.heading_image = response.data;
                          this.languageState[this.state.current_lang].heading_image = response.data;
                        } else {
                          nlData.heading_image = response.data;
                          this.languageState[this.state.current_lang].heading_image = response.data;
                        }
                        // console.log(response);
                        me.image = null;
                        this.formDirty = false;
                        this.setState({isAdd: false});
                        this.props.store.addNotification({
                          message: 'Job sucessfully saved.',
                          title: 'Success',
                          type: 'success',
                          container: "top-right",
                          dismiss: {
                            duration: 3000,
                            onScreen: true
                          }
                        });
                        this.setState({
                          isAdd: false, 
                          list: true, 
                          row: null,
                          spinnerShow: false,
                          image_preview: null
                        }, () => {
                          this.getAllJobs();
                          this.languageState = {
                            en: null,
                            nl: null
                          }
                        });
                      }
                    ).catch(
                      (err) => {
                        console.error(err);
                      }
                    ).finally(
                      () => {
        
                      }
                    )
        
                  } else {
                    this.props.store.addNotification({
                      message: 'Job sucessfully saved.',
                      title: 'Success',
                      type: 'success',
                      container: "top-right",
                      dismiss: {
                        duration: 3000,
                        onScreen: true
                      }
                    });
                  }
                  this.setState({
                    isAdd: false, 
                    list: true, 
                    row: null,
                    spinnerShow: false,
                    image_preview: null
                  }, () => {
                    this.getAllJobs()
                    this.languageState = {
                      en: null,
                      nl: null
                    }
                  });
                  // console.log(response.data);
                }
              ).catch(
                (err) => {
        
                }
              ).finally(
                () => {
                  
                }
              )
            } else {
              this.setState({
                isAdd: false, 
                list: true, 
                row: null,
                spinnerShow: false,
                image_preview: null
              }, () => {
                this.getAllJobs()
                this.languageState = {
                  en: null,
                  nl: null
                }
              });
            }
          }
          // console.log(response.data);
        }
      ).catch(
        (err) => {

        }
      ).finally(
        () => {
          
        }
      )
    }
  }

  handleDateChange = date => {
    this.setState({ published: date });
    const newDate = date.toISOString().replace('T', ' ').replace('.000Z', '');
    this.formData.published = newDate;
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === 'image') {
      // console.log(target);
    }
    if (name === 'active' && this.state.row) {
      let row = this.state.row;
      row.active = !this.state.row.active;
      this.setState({ row: row }, () => {
        //console.log(this.state.row.active, this.state);
      })
      // this.state.row.active = !this.state.row.active;
    } else if (name === 'active' && !this.state.row) {
      this.setState({ checkHandler: !this.state.checkHandler }, () => {
        // console.log(this.state.checkHandler);
      })
      // this.state.checkHandler = !this.state.checkHandler;
    }
    this.formData[name] = name === 'active' ? target.checked : value;
    // console.log(this.formData);
  }

  /**
   * Image upload handler
   */
  uploadImage = event => {
    const target = event.target;
    const file = target.files[0];
    this.image = file;
    this.formDirty = true;
    var reader = new FileReader();
    // console.log(reader, reader.result,file);
    if (!this.state.isAdd && this.state.row && this.state.row.testimony_image) {
      const row = this.state.row;
      row.testimony_image = null;
      this.setState({row: row});
    }
    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      // console.log(e, this, e.target.result);
      this.setState({ image_preview: reader.result }, () => {
        // console.log(this.state.image_preview);
      })
    }
    
    // console.log(reader.readAsDataURL(file));
  }

  handleLanguageChange = event => {
    const target = event.target;
    this.setState({ current_lang: target.value, spinnerShow: true });
    // console.log(target.value, this.languageState);
    if (this.languageState[target.value] !== null) {
      this.setState({row: this.languageState[target.value]});
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!this.state.isAdd) {
        axios.get(`${this.apiEndPoint}/jobs${target.value}s?id=${this.state.row.id}`, 
          {
            headers: { 'Authorization': `Bearer ${user.jwt}` } 
          }
        ).then(
          (response) => {
            this.languageState[target.value] = response.data[0];
            // console.log('after req end', this.languageState, response);
            this.setState({row: this.languageState[target.value], spinnerShow: false});
          }
        ).catch(
          (err) => {
            console.error(err);
          }
        ).finally(
          () => {

          }
        )
      }
    }
  }

  handleLanguageChangeList = event => {
    const target = event.target;
    const value = target.value;
    this.setState({ current_lang: value }, () => {
      this.getAllJobs();
    });
  }

  renderHTML(text) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.mdParser.render(text))
      }, 1000)
    })
  }

  searchData = event => {
    const target = event.target;
    const value = target.value;
    let searchResult = [];
    if (this.initialData && this.initialData.length !== 0) {
      for (const item of this.initialData) {
        let pushThis = false;
        for (const prop in item) {
          if (prop === 'job_title' || prop === 'country' || prop === 'city') {
            if (typeof item[prop] === 'string') {
              if (item[prop].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                pushThis = true;
              }
            }
          }
        }
        if (pushThis) {
          searchResult.push(item);
        }
      }
      this.setState({data: searchResult})
    } else {
      this.setState({data: this.initialData})
    }
  }

  render() {
    const { show, list, data, columns } = this.state
    // console.log('TABLE DATA', data);
    if (!show) {
      return null;
    } else {
      return(
        <div>
          <Spinner show={this.state.spinnerShow}/>
          <div className="admin-content-subheader">
            <div className="admin-subheader-left">
              {list ? (
                  <span className="fa fa-plus-circle admin-add-btn" onClick={this.addJob}></span>
                ) : (
                  <span className="fa fa-caret-left admin-back-btn" onClick={this.goBack}><span className="admin-back-text">Back</span></span>
                )
              }
            </div>
            <div className="admin-subheader-right">
              {list ? (
                  <div>
                    <select className="lang-select" name="current_lang" defaultValue="en" onChange={this.handleLanguageChangeList}>
                      <option value="en">
                        EN
                      </option>
                      <option value="nl">
                        NL
                      </option>
                    </select>
                    <input placeholder="Search" onChange={this.searchData}/>
                  </div>
                ) : (
                  <div>
                    <select className="lang-select" name="current_lang" defaultValue="en" onChange={this.handleLanguageChange}>
                      <option value="en">
                        EN
                      </option>
                      <option value="nl">
                        NL
                      </option>
                    </select>
                    <button className="admin-save-button" onClick={() => this.saveJob(this)}>Save</button>
                  </div>
                )
              }
            </div>
          </div>
          <div className="admin-content-warpper">
            {list ? (
              <div className="content-list-wrapper custom-table">
                <DataTable
                  title="Jobs"
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 275px)"
                />
              </div>
            ) : (
              <div className="content-form-wrapper">
                <form>
                  <div className="admin-form-section-3">
                    <div className="admin-input-wrapper">
                      <label>Category</label>
                      <select name="category" onChange={this.handleInputChange}>
                        <option value="acc">
                          ACC Jobs
                        </option>
                        <option value="portfolio">
                          Portfolio Jobs
                        </option>
                      </select>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Job Type</label>
                      <select name="job_type" onChange={this.handleInputChange}>
                        <option value="full">
                          Full time
                        </option>
                        <option value="part">
                          Part ime
                        </option>
                      </select>
                      {/*<input className={this.state.valid['summary'] ? '' : 'input-required'} required name="summary" onChange={this.handleInputChange}/>*/}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Job title</label>
                      <input name="job_title" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label>Active</label>
                      <label className="switch">
                        <input type="checkbox" checked={this.state.isAdd ? this.state.checkHandler : (this.state.row ? this.state.row.active : this.state.checkHandler)} name="active" onChange={this.handleInputChange} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="admin-form-section-3">
                    <div className="admin-input-wrapper">
                      <label className="required">Employee testimony</label>
                      <input name="employee_testimony" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">City</label>
                      <input name="city" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Country</label>
                      <input name="country" required onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Employee name</label>
                      <input name="employee_name" required onChange={this.handleInputChange}/>
                    </div>
                  </div>
                  <div className="admin-form-section-3">
                    <div className="admin-image-upload">
                      <label>Image</label>
                      <div className="image-preview-wrapper">
                        <img alt="" src={this.state.row !== null && (this.state.row.testimony_image !== undefined && this.state.row.testimony_image !== null) ? 
                                        (this.apiEndPoint + '/' + this.state.row.testimony_image.url) : 
                                        (this.state.image_preview !== null ? this.state.image_preview : '')}></img>
                      </div>
                      <input className="file-input" name="image" type="file" onChange={this.uploadImage}/>
                    </div>
                  </div>
                  
                  <div className="admin-editor-wrapper">
                    <label className="required">Job description</label>
                    <div>
                      <MdEditor
                        ref={node => this.mdEditor = node}
                        value={this.state.row !== null ? this.state.row.job_text : ''}
                        renderHTML={(text) => this.mdParser.render(text)}
                        onChange={this.handleEditorChange}
                        required
                      />
                    </div> 
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )
    }
  }
}