import React, { Component } from 'react';
import axios from 'axios';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import DataTable from 'react-data-table-component';
import Spinner from './Spinner';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-notifications-component/dist/theme.css';


export default class AdminPortfolio extends Component {

  client = new ApolloClient({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  initialData = null;

  mdEditor = null;

  mdParser = null;

  aquired = new Date();

  sold = new Date();

  formData = {};

  image = null;

  portfolioImage = null;

  companyImage = null;

  companyLogo = null;

  initialLang = 'en';

  formDirty = false;

  valid = {};

  firstSectionData = [];

  secondSectionData = {
    heading: '',
    text: '',
    cards: []
  };

  secondSectionCards = [];

  treeBlocks = [];

  languageState = {
    en: null,
    nl: null
  };

  constructor(props) {
    super(props);
    // console.log('estasdasd', this.props);
    this.mdParser = new MarkdownIt();
    this.state = {
      refresh: true,
      show: this.props.show,
      list: true,
      row: null,
      isAdd: true,
      current_lang: 'en',
      current_lang_list: 'en',
      checkHandler: true,
      aquired: new Date(),
      sold: new Date(),
      portfolio_image_preview: null,
      company_image_preview: null,
      company_logo_preview: null,
      spinnerShow: false,
      valid: {},
      saving: false,
      company_related_portfolio: [],
      firstSectionData: [],
      secondSectionCards: [],
      secondSectionData: {
        heading: '',
        text: '',
        cards: []
      },
      treeBlocks: [],
      columns: [
        {
          name: 'Action',
          cell: row => <div><span className="fa fa-edit admin-table-edit" onClick={() => this.handleEdit(row)}></span><span className="fa fa-trash admin-table-delete" onClick={() => this.handleDelete(row)}></span></div>,
          maxWidth: '40px'
        },
        {
          name: 'ID',
          selector: 'id',
          soratble: true
        },
        {
          name: 'Business',
          selector: 'business',
          soratble: true
        },
        {
          name: 'Client',
          selector: 'client',
          soratble: true
        },
        {
          name: 'Active',
          cell: row => <div>{row.active ? 'True' : 'False'}</div>,
          soratble: true
        }
      ],
      data: []
    }
  }

  handleEdit = row => {
    this.formData = {};
    this.setState({
      spinnerShow: true,
      list: false, 
      row: row,
      isAdd: false,
      aquired: new Date(row.aquired.replace(' ', 'T') + '.000Z'),
      sold: row.Sold ? new Date(row.Sold.replace(' ', 'T') + '.000Z') : new Date(),
      firstSectionData: row.first_section,
      secondSectionData: row.second_section,
      secondSectionCards: row.second_section.cards,
      treeBlocks: row.tree }, () => {
        this.firstSectionData = row.first_section;
        this.secondSectionData = row.second_section;
        this.secondSectionCards = row.second_section.cards;
        this.treeBlocks = row.tree;
        // console.log(this.state.row.aquired);
        this.languageState = {
          en: null,
          nl: null
        }
        this.languageState[this.initialLang] = row;
        this.getCompaniesDropdown();
        this.setState({spinnerShow: false,});
        this.state.row.aquired = this.state.row.aquired;
      });
    // document.getElementsByName('client')[0] = row.client;
    
  }

  getCompaniesDropdown() {
    this.client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          portfolio${this.state.current_lang}s (
            where: {
              active: true
            }
          ) {
            client
            id
          }
        }
      `
    }).then(
      (response) => {
        this.setState({ company_related_portfolio: response.data[`portfolio${this.state.current_lang}s`]});
      }
    )
  }

  goBack = event => {
    this.setState(
      { list: true, 
        row: null, firstSectionData: [], 
        secondSectionCards: [], 
        treeBlocks: [],
        company_image_preview: null,
        portfolio_image_preview: null,
        company_logo_preview: null,
      }, () => {
        this.portfolioImage = null;
        this.companyImage = null;
        this.companyLogo = null;
        this.getAllPortfolios();
        this.languageState = {
          en: null,
          nl: null
        }
      });
  }

  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  getAllPortfolios() {
    this.setState({ spinnerShow: true });
    const user = JSON.parse(localStorage.getItem('user'));
      // console.log(user);
      if (user !== null && !this.isObjectEmpty(user)) {
        axios.get(`${this.apiEndPoint}/portfolio${this.state.current_lang}s`, {
          headers: { 'Authorization': `Bearer ${user.jwt}` }
        }).then(
          (response) => {
            this.initialData = response.data;
            response.data.forEach(
              (element) => {
                if (element.aquired) {
                  element.aquired = element.aquired.replace('T', ' ').split('.')[0];
                  console.log(element.aquired);
                }

                if (element.Sold) {
                  element.Sold = element.Sold.replace('T', ' ').split('.')[0];
                }
              }
            )
            
            this.setState({ data: response.data }, () => {
              this.setState({spinnerShow: false});
            });
            // console.log(response);
          }
        ).catch(
          (err) => {
            // console.error(err);
          }
        ).finally(
          () => {

          }
        )
      }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      show: props.show
    }
    // this.setState({show: props.show})
    // if (props.show) {
      
    // }
  }

  addFirstSectionData = event => {
    this.firstSectionData = this.state.firstSectionData;
    this.firstSectionData.push({heading: '', text: '', image: ''});
    this.setState({firstSectionData: this.firstSectionData});
  }

  removeFirstSectionData(index) {
    this.firstSectionData = this.state.firstSectionData;
    this.firstSectionData.splice(index, 1);
    this.setState({firstSectionData: this.firstSectionData});
  }

  addSecondSectionCards = event => {
    event.preventDefault();
    this.secondSectionCards = this.state.secondSectionCards;
    this.secondSectionCards.push({cardHeading: '', cardText: ''});
    this.setState(
    {
      secondSectionCards: this.secondSectionCards, 
      secondSectionData: { 
        heading: this.state.secondSectionData.heading, 
        text: this.state.secondSectionData.text, 
        cards: this.secondSectionCards 
      }
    })
  }

  removeSecondSectionCards(index) {
    this.secondSectionCards = this.state.secondSectionCards;
    this.secondSectionCards.splice(index, 1);
    this.setState({secondSectionCards: this.secondSectionCards});
  }

  addTreeBlock = event => {
    event.preventDefault();
    this.treeBlocks = this.state.treeBlocks;
    this.treeBlocks.push({heading: '', text: ''});
    this.setState({treeBlocks: this.treeBlocks})
  }

  removeTreeBlock(index) {
    this.treeBlocks = this.state.treeBlocks;
    this.treeBlocks.splice(index, 1);
    this.setState({treeBlocks: this.treeBlocks});
  }

  /**
   * On component update.
   */
  componentDidUpdate() {
    if (this.props.show) {
      // console.log('comp did update', this.state);
      if (this.state.show && this.state.data === null) {
        this.getAllPortfolios();
      } else {
        if (!this.state.saving) {
          if (this.state.row !== null) {
            for (const props in this.state.row) {
              // console.log(props, this.state.row[props]);
              if (props === 'aquired') {
                // console.log('THIS IS AQUIRED');
                this.aquired = this.state.row[props];
  
                // this.setState({ aquired: new Date(this.state.row[props])});
                // this.state.row[props] = new Date(this.state.row[props]);
                // console.log(this.state.row[props]);
              }

              if (props.toLowerCase() === 'sold') {
                this.sold = this.state.row[props];
              }
              
              if (document.getElementsByName(props)[0]) {
                if (props === 'aquired' || props === 'Sold') {
                  
                } else {
                  if (props !== 'company_image' && props !== 'portfolio_image' && props !== 'company_logo') {
                    document.getElementsByName(props)[0].value = this.state.row[props];
                  }
                }
              }
            }
            
            
            // console.log(document.getElementsByName('client')[0], document.getElementsByName('client')[0].value);
            
            // console.log(document.getElementsByName('client'));
          }
  
          if (this.state.current_lang !== this.initialLang) {
            if (this.formDirty) {
              if (window.confirm('Are you sure you want to discard changes?')) {
                this.resetForm();
              }
            } else {
              if (this.languageState[this.state.current_lang] === null) {
                this.resetForm();
              }
            }
          }
        }
      }
    }
  }

  componentDidMount() {
    this.getAllPortfolios();
  }

  addPortfolio = event => {
    this.setState({ list: false, isAdd: true, row: null }, () => {
      this.getCompaniesDropdown();
    });
  }

  handleDateChange = (name, date) => {
    if (!date) {
      return;
    }

    const lowerCasedName = name.toLowerCase();
    this.setState({ [lowerCasedName]: date });
    const newDate = date.toISOString().replace('T', ' ').replace('.000Z', '');
    this.formData[name] = newDate;
    if (this.languageState[this.state.current_lang] !== null) {
      this.languageState[this.state.current_lang]['published'] = newDate;
    }
    this.formDirty = true;
  }

  uploadImage = event => {
    const target = event.target;
    const file = target.files[0];
    // this.image = file;
    this.formDirty = true;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    if (target.name === 'portfolio_image') {
      this.portfolioImage = file;
      reader.onloadend = (e) => {
        this.setState({ portfolio_image_preview: reader.result }, () => {
        })
      }
    } else if (target.name === 'company_image') {
      this.companyImage = file;
      reader.onloadend = (e) => {
        this.setState({ company_image_preview: reader.result }, () => {
        })
      }
    } else if (target.name === 'company_logo') {
    this.companyLogo = file;
    reader.onloadend = (e) => {
      this.setState({ company_logo_preview: reader.result }, () => {
      })
    }
  }
  }

  savePortfolio(me) {
    this.setState({ saving: true });
    if (this.state.isAdd) {
      this.saveNewPortfolio(me);
    } else {
      this.editPortfolio(me);
    }
  }

  editPortfolio(me) {
    let enData = {};
    let nlData = {};
    me.formData.first_section = me.state.firstSectionData;
    me.formData.second_section = me.state.secondSectionData;
    me.formData.tree = me.state.treeBlocks;
    me.formData.aquired = me.state.aquired;
    me.formData.Sold = me.state.sold
    this.setState({ spinnerShow: true });
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null && !this.isObjectEmpty(user)) {
      axios.put(`${me.apiEndPoint}/portfolio${me.state.current_lang}s/${me.state.row.id}`, me.formData,{
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          if (this.state.current_lang === 'en') {
            enData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          } else {
            nlData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          }
          this.uploadImagesToServer(me, response.data);
        }
      ).catch(
        (err) => {
          console.error(err);
        }
      ).finally(
        () => {
          this.setState({ saving: false });
        }
      )
    }
  }

  saveNewPortfolio(me) {
    let enData = {};
    let nlData = {};
    me.formData.first_section = me.state.firstSectionData;
    me.formData.second_section = me.state.secondSectionData;
    me.formData.tree = me.state.treeBlocks;
    me.formData.aquired = me.state.aquired;
    me.formData.Sold = me.state.sold;
    if (me.formData.category === undefined) {
      me.formData.category = document.getElementsByName('category')[0].value;
    }
    me.setState({spinnerShow: true});
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== null && !this.isObjectEmpty(user)) {
      axios.post(`${me.apiEndPoint}/portfolio${me.state.current_lang}s`, me.formData,{
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          if (this.state.current_lang === 'en') {
            enData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          } else {
            nlData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          }
          this.uploadImagesToServer(me, response.data);
        }
      ).catch(
        (err) => {
          console.error(err);
        }
      ).finally(
        () => {
          this.setState({ saving: false });
        }
      )
    }
  }

  uploadImagesToServer(me, response, otherLang) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (otherLang !== undefined || me.state.row !== null) {
      this.setState({isAdd: false});
    }
    let enData = {};
    let nlData = {};
    if (
        (this.portfolioImage !== null && me.portfolioImage instanceof File) &&
        (this.companyImage !== null && me.companyImage instanceof File) &&
        (this.companyLogo !== null && me.companyLogo instanceof File)
      ) {
      const portfolioForm = new FormData();
      portfolioForm.append('files', me.portfolioImage);
      portfolioForm.append('refId', response.id);
      portfolioForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      portfolioForm.append('field', 'portfolio_image');
      axios.post(`${me.apiEndPoint}/upload/`, portfolioForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseFirst) => {
          if (this.state.current_lang === 'en') {
            enData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          } else {
            nlData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          }
          const companyForm = new FormData();
          companyForm.append('files', me.companyImage);
          companyForm.append('refId', response.id);
          companyForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
          companyForm.append('field', 'company_image');
          axios.post(`${me.apiEndPoint}/upload/`, companyForm, {
            headers: {
              'Authorization': `Bearer ${user.jwt}`,
              'Content-Type': 'multipart/form-data'
            }
          }).then(
            (responseSecond) => {
              if (this.state.current_lang === 'en') {
                enData.company_logo = responseSecond.data;
                this.languageState[this.state.current_lang].company_logo = responseSecond.data;
              } else {
                nlData.company_logo = responseSecond.data;
                this.languageState[this.state.current_lang].company_logo = responseSecond.data;
              }
              const logoForm = new FormData();
              logoForm.append('files', me.companyLogo);
              logoForm.append('refId', response.id);
              logoForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
              logoForm.append('field', 'company_logo');
              axios.post(`${me.apiEndPoint}/upload/`, logoForm, {
                headers: {
                  'Authorization': `Bearer ${user.jwt}`,
                  'Content-Type': 'multipart/form-data'
                }
              }).then(
                (responseThird) => {
                  if (this.state.current_lang === 'en') {
                    enData.company_logo = responseThird.data;
                    this.languageState[this.state.current_lang].company_logo = responseThird.data;
                  } else {
                    nlData.company_logo = responseThird.data;
                    this.languageState[this.state.current_lang].company_logo = responseThird.data;
                  }
                  this.formDirty = false;
                  if (me.state.isAdd) {
                    this.autoSaveOtherLanguage()
                  } else {
                    this.props.store.addNotification({
                      message: 'Portfolio sucessfully saved.',
                      title: 'Success',
                      type: 'success',
                      container: "top-right",
                      dismiss: {
                        duration: 3000,
                        onScreen: true
                      }
                    });
                    this.setState({
                      list: true,
                      spinnerShow: false,
                      portfolio_image_preview: null,
                      company_logo_preview: null,
                      company_image_preview: null,
                      firstSectionData: null,
                      secondSectionData: null,
                      secondSectionCards: null,
                      treeBlocks: null
                    }, () => {
                      this.firstSectionData = null;
                      this.secondSectionData = null;
                      this.secondSectionCards = null;
                      this.treeBlocks = null;
                      this.portfolioImage = null;
                      this.companyImage = null;
                      this.companyLogo = null;
                      this.getAllPortfolios();
                      this.languageState = {
                        en: null,
                        nl: null
                      }
                    });
                  }
                }
              ).catch(
                (err) => {
                  console.error(err);
                }
              ).finally(

              )
            }
          ).catch(

          ).finally(

          )
          /*
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          }
          */
        }
      ).catch(

      ).finally(

      )
    } else if (
      (this.portfolioImage !== null && me.portfolioImage instanceof File) &&
      (this.companyImage !== null && me.companyImage instanceof File)
    ) {
      const portfolioForm = new FormData();
      portfolioForm.append('files', me.portfolioImage);
      portfolioForm.append('refId', response.id);
      portfolioForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      portfolioForm.append('field', 'portfolio_image');
      axios.post(`${me.apiEndPoint}/upload/`, portfolioForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseFirst) => {
          if (this.state.current_lang === 'en') {
            enData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          } else {
            nlData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          }
          const companyForm = new FormData();
          companyForm.append('files', me.companyImage);
          companyForm.append('refId', response.id);
          companyForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
          companyForm.append('field', 'company_image');
          axios.post(`${me.apiEndPoint}/upload/`, companyForm, {
            headers: {
              'Authorization': `Bearer ${user.jwt}`,
              'Content-Type': 'multipart/form-data'
            }
          }).then(
            (responseSecond) => {
              if (this.state.current_lang === 'en') {
                enData.company_logo = responseSecond.data;
                this.languageState[this.state.current_lang].company_logo = responseSecond.data;
              } else {
                nlData.company_logo = responseSecond.data;
                this.languageState[this.state.current_lang].company_logo = responseSecond.data;
              }
              this.formDirty = false;
              if (me.state.isAdd) {
                this.autoSaveOtherLanguage()
              } else {
                this.props.store.addNotification({
                  message: 'Portfolio sucessfully saved.',
                  title: 'Success',
                  type: 'success',
                  container: "top-right",
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
                this.setState({
                  list: true,
                  spinnerShow: false,
                  portfolio_image_preview: null,
                  company_logo_preview: null,
                  company_image_preview: null,
                  firstSectionData: null,
                  secondSectionData: null,
                  secondSectionCards: null,
                  treeBlocks: null
                }, () => {
                  this.firstSectionData = null;
                  this.secondSectionData = null;
                  this.secondSectionCards = null;
                  this.treeBlocks = null;
                  this.portfolioImage = null;
                  this.companyImage = null;
                  this.companyLogo = null;
                  this.getAllPortfolios();
                  this.languageState = {
                    en: null,
                    nl: null
                  }
                });
              }
              
            }
          ).catch(

          ).finally(

          )
          /*
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          }
          */
        }
      ).catch(

      ).finally(

      )
    } else if (
      (this.portfolioImage !== null && me.portfolioImage instanceof File) &&
      (this.companyLogo !== null && me.companyLogo instanceof File)
    ) {
      const portfolioForm = new FormData();
      portfolioForm.append('files', me.portfolioImage);
      portfolioForm.append('refId', response.id);
      portfolioForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      portfolioForm.append('field', 'portfolio_image');
      axios.post(`${me.apiEndPoint}/upload/`, portfolioForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseFirst) => {
          if (this.state.current_lang === 'en') {
            enData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          } else {
            nlData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          }
          const logoForm = new FormData();
            logoForm.append('files', me.companyLogo);
            logoForm.append('refId', response.id);
            logoForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
            logoForm.append('field', 'company_logo');
            axios.post(`${me.apiEndPoint}/upload/`, logoForm, {
              headers: {
                'Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'multipart/form-data'
              }
            }).then(
              (responseThird) => {
                if (this.state.current_lang === 'en') {
                  enData.company_logo = responseThird.data;
                  this.languageState[this.state.current_lang].company_logo = responseThird.data;
                } else {
                  nlData.company_logo = responseThird.data;
                  this.languageState[this.state.current_lang].company_logo = responseThird.data;
                }
                this.formDirty = false;
                if (me.state.isAdd) {
                  this.autoSaveOtherLanguage()
                } else {
                  this.props.store.addNotification({
                    message: 'Portfolio sucessfully saved.',
                    title: 'Success',
                    type: 'success',
                    container: "top-right",
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
                  this.setState({
                    list: true,
                    spinnerShow: false,
                    portfolio_image_preview: null,
                    company_logo_preview: null,
                    company_image_preview: null,
                    firstSectionData: null,
                    secondSectionData: null,
                    secondSectionCards: null,
                    treeBlocks: null
                  }, () => {
                    this.firstSectionData = null;
                    this.secondSectionData = null;
                    this.secondSectionCards = null;
                    this.treeBlocks = null;
                    this.portfolioImage = null;
                    this.companyImage = null;
                    this.companyLogo = null;
                    this.getAllPortfolios();
                    this.languageState = {
                      en: null,
                      nl: null
                    }
                  });
                }
              }
            ).catch(
              (err) => {
                console.error(err);
              }
            ).finally(

            )
          /*
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          }
          */
        }
      ).catch(

      ).finally(

      )
    } else if (
      (this.companyImage !== null && me.companyImage instanceof File) &&
      (this.companyLogo !== null && me.companyLogo instanceof File)
    ) {
      const companyForm = new FormData();
      companyForm.append('files', me.companyImage);
      companyForm.append('refId', response.id);
      companyForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      companyForm.append('field', 'company_image');
      axios.post(`${me.apiEndPoint}/upload/`, companyForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseSecond) => {
          if (this.state.current_lang === 'en') {
            enData.company_logo = responseSecond.data;
            this.languageState[this.state.current_lang].company_logo = responseSecond.data;
          } else {
            nlData.company_logo = responseSecond.data;
            this.languageState[this.state.current_lang].company_logo = responseSecond.data;
          }
          const logoForm = new FormData();
          logoForm.append('files', me.companyLogo);
          logoForm.append('refId', response.id);
          logoForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
          logoForm.append('field', 'company_logo');
          axios.post(`${me.apiEndPoint}/upload/`, logoForm, {
            headers: {
              'Authorization': `Bearer ${user.jwt}`,
              'Content-Type': 'multipart/form-data'
            }
          }).then(
            (responseThird) => {
              if (this.state.current_lang === 'en') {
                enData.company_logo = responseThird.data;
                this.languageState[this.state.current_lang].company_logo = responseThird.data;
              } else {
                nlData.company_logo = responseThird.data;
                this.languageState[this.state.current_lang].company_logo = responseThird.data;
              }
              this.formDirty = false;
              if (me.state.isAdd) {
                this.autoSaveOtherLanguage()
              } else {
                this.props.store.addNotification({
                  message: 'Portfolio sucessfully saved.',
                  title: 'Success',
                  type: 'success',
                  container: "top-right",
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
                this.setState({
                  list: true,
                  spinnerShow: false,
                  portfolio_image_preview: null,
                  company_logo_preview: null,
                  company_image_preview: null,
                  firstSectionData: null,
                  secondSectionData: null,
                  secondSectionCards: null,
                  treeBlocks: null
                }, () => {
                  this.firstSectionData = null;
                  this.secondSectionData = null;
                  this.secondSectionCards = null;
                  this.treeBlocks = null;
                  this.portfolioImage = null;
                  this.companyImage = null;
                  this.companyLogo = null;
                  this.getAllPortfolios();
                  this.languageState = {
                    en: null,
                    nl: null
                  }
                });
              }
            }
          ).catch(
            (err) => {
              console.error(err);
            }
          ).finally(

          )
        }
      ).catch(

      ).finally(

      )
      
    } else if (me.portfolioImage !== null && me.portfolioImage instanceof File) {
      const portfolioForm = new FormData();
      portfolioForm.append('files', me.portfolioImage);
      portfolioForm.append('refId', response.id);
      portfolioForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      portfolioForm.append('field', 'portfolio_image');
      axios.post(`${me.apiEndPoint}/upload/`, portfolioForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseFirst) => {
          if (this.state.current_lang === 'en') {
            enData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          } else {
            nlData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].portfolio_image = responseFirst.data;
          }
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          } else {
            this.props.store.addNotification({
              message: 'Portfolio sucessfully saved.',
              title: 'Success',
              type: 'success',
              container: "top-right",
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.setState({
              list: true,
              spinnerShow: false,
              portfolio_image_preview: null,
              company_logo_preview: null,
              company_image_preview: null,
              firstSectionData: null,
              secondSectionData: null,
              secondSectionCards: null,
              treeBlocks: null
            }, () => {
              this.firstSectionData = null;
              this.secondSectionData = null;
              this.secondSectionCards = null;
              this.treeBlocks = null;
              this.portfolioImage = null;
              this.companyImage = null;
              this.companyLogo = null;
              this.getAllPortfolios();
              this.languageState = {
                en: null,
                nl: null
              }
            });
          }
          /*
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          }
          */
        }
      ).catch(

      ).finally(

      )
    } else if (me.companyImage !== null && me.companyImage instanceof File) {
      const companyForm = new FormData();
      companyForm.append('files', me.companyImage);
      companyForm.append('refId', response.id);
      companyForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      companyForm.append('field', 'company_image');
      axios.post(`${me.apiEndPoint}/upload/`, companyForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseFirst) => {
          if (this.state.current_lang === 'en') {
            enData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].company_image = responseFirst.data;
          } else {
            nlData.portfolio_image = responseFirst.data;
            this.languageState[this.state.current_lang].company_image = responseFirst.data;
          }
          
          this.formDirty = false;
          if (this.state.isAdd) {
            this.autoSaveOtherLanguage()
          } else {
            this.props.store.addNotification({
              message: 'Portfolio sucessfully saved.',
              title: 'Success',
              type: 'success',
              container: "top-right",
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.setState({
              list: true,
              isAdd: true,
              spinnerShow: false,
              portfolio_image_preview: null,
              company_logo_preview: null,
              company_image_preview: null,
              firstSectionData: null,
              secondSectionData: null,
              secondSectionCards: null,
              treeBlocks: null
            }, () => {
              this.firstSectionData = null;
              this.secondSectionData = null;
              this.secondSectionCards = null;
              this.treeBlocks = null;
              this.portfolioImage = null;
              this.companyImage = null;
              this.companyLogo = null;
              this.getAllPortfolios();
              this.languageState = {
                en: null,
                nl: null
              }
            });
          }
        }
      ).catch(

      ).finally(
        
      )
    } else if (me.companyLogo !== null && me.companyLogo instanceof File) {
      const logoForm = new FormData();
      logoForm.append('files', me.companyLogo);
      logoForm.append('refId', response.id);
      logoForm.append('ref', `portfolio${otherLang !== undefined ? otherLang : this.state.current_lang}`);
      logoForm.append('field', 'company_logo');
      axios.post(`${me.apiEndPoint}/upload/`, logoForm, {
        headers: {
          'Authorization': `Bearer ${user.jwt}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        (responseFirst) => {
          if (this.state.current_lang === 'en') {
            enData.company_logo = responseFirst.data;
            this.languageState[this.state.current_lang].company_logo = responseFirst.data;
          } else {
            nlData.company_logo = responseFirst.data;
            this.languageState[this.state.current_lang].company_logo = responseFirst.data;
          }
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          } else {
            this.props.store.addNotification({
              message: 'Portfolio sucessfully saved.',
              title: 'Success',
              type: 'success',
              container: "top-right",
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.setState({
              list: true,
              isAdd: true,
              spinnerShow: false,
              portfolio_image_preview: null,
              company_logo_preview: null,
              company_image_preview: null,
              firstSectionData: null,
              secondSectionData: null,
              secondSectionCards: null,
              treeBlocks: null
            }, () => {
              this.firstSectionData = null;
              this.secondSectionData = null;
              this.secondSectionCards = null;
              this.treeBlocks = null;
              this.portfolioImage = null;
              this.companyImage = null;
              this.companyLogo = null;
              this.languageState = {
                en: null,
                nl: null
              }
              this.getAllPortfolios();
            });
          }
          /*
          this.formDirty = false;
          if (me.state.isAdd) {
            this.autoSaveOtherLanguage()
          }
          */
        }
      ).catch(

      ).finally(

      )
    } else {
      if (this.state.isAdd) {
        this.autoSaveOtherLanguage();
      } else {
        this.props.store.addNotification({
          message: 'Portfolio sucessfully saved.',
          title: 'Success',
          type: 'success',
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        this.setState({ 
          spinnerShow: false, 
          list: true, 
          isAdd: true,
          company_image_preview: null,
          portfolio_image_preview: null,
          company_logo_preview: null,
          firstSectionData: null,
          secondSectionData: null,
          secondSectionCards: null,
          treeBlocks: null
        }, () => {
          this.firstSectionData = null;
          this.secondSectionData = null;
          this.secondSectionCards = null;
          this.treeBlocks = null;
          this.portfolioImage = null;
          this.companyImage = null;
          this.companyLogo = null;
          this.getAllPortfolios();
          this.languageState = {
            en: null,
            nl: null
          }
        });
      }
    }
  }

  autoSaveOtherLanguage() {
    const user = JSON.parse(localStorage.getItem('user'));
    let otherLang = '';
    if (this.state.current_lang === 'en') {
      otherLang = 'nl';
    } else {
      otherLang = 'en';
    }
    if (this.state.isAdd) {
      axios.post(`${this.apiEndPoint}/portfolio${otherLang}s`, this.formData, {
        headers: {'Authorization': `Bearer ${user.jwt}`}
      }).then(
        (response) => {
          if (this.state.current_lang === 'en') {
            // enData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          } else {
            // nlData = response.data;
            this.languageState[this.state.current_lang] = response.data;
          }
          if (
            (this.portfolioImage !== null && this.portfolioImage instanceof File) ||
            (this.companyImage !== null && this.companyImage instanceof File) ||
            (this.companyLogo !== null && this.companyLogo instanceof File)
          ) {
            this.uploadImagesToServer(this, response.data, otherLang);
          }
        }
      ).catch(
  
      ).finally(
        () => {
          this.props.store.addNotification({
            message: 'Portfolio sucessfully saved.',
            title: 'Success',
            type: 'success',
            container: "top-right",
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          this.setState({
            spinnerShow: false, 
            list: true, 
            isAdd: true,
            company_image_preview: null,
            portfolio_image_preview: null,
            company_logo_preview: null,
            firstSectionData: null,
            secondSectionData: null,
            secondSectionCards: null,
            treeBlocks: null
          }, () => {
            this.firstSectionData = null;
            this.secondSectionData = null;
            this.secondSectionCards = null;
            this.treeBlocks = null;
            this.portfolioImage = null;
            this.companyImage = null;
            this.companyLogo = null;
            this.getAllPortfolios();
          })
        }
      )
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;


    if (name === 'client') {
      this.formData[name] = value;
      document.getElementsByName('slug')[0].value = this.formData[name].toLowerCase().replace(/ /g, '_').replace(/\-|\.|\,|\'|\(|\)|\"/g, '').replace(/__/g, '_');
      this.formData['slug'] = this.formData[name].toLowerCase().replace(/ /g, '_').replace(/\-|\.|\,|\'|\(|\)|\"/g, '').replace(/__/g, '_');
      if (this.languageState[this.state.current_lang] !== null) {
        this.languageState[this.state.current_lang]['slug'] = value.toLowerCase().replace(/ /g, '_').replace(/\-|\.|\,|\'|\(|\)|\"/g, '').replace(/__/g, '_');
      }
    } else {
      if (name === 'active' && this.state.row) {
        let row = this.state.row;
        row.active = !this.state.row.active;
        this.setState({ row: row }, () => {
          console.log(this.state.row.active, this.state);
        })
      } else if (name === 'active' && !this.state.row) {
        this.setState({ checkHandler: !this.state.checkHandler }, () => {
          console.log(this.state.checkHandler);
        })
      }
      console.log(this.formData['active'])
      this.formData[name] = name === 'active' ? target.checked : value;
      console.log(name, this.formData[name]);
      if (this.languageState[this.state.current_lang] !== null) {
        this.languageState[this.state.current_lang][name] = name === 'active' ? target.checked : value;
      }
    }
  }

  handleDynamicChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name.indexOf('first-section') !== -1) {
      if (name.indexOf('heading') !== -1) {
        const tempArr = name.split('-');
        const index = parseInt(tempArr[tempArr.length - 1], 10);
        this.firstSectionData[index].heading = value;
        this.setState({firstSectionData: this.firstSectionData});
      } else if (name.indexOf('text') !== -1) {
        const tempArr = name.split('-');
        const index = tempArr[tempArr.length - 1];
        this.firstSectionData[index].text = value;
        this.setState({firstSectionData: this.firstSectionData});
      } else {
        // console.log(target);
        const tempArr = name.split('-');
        const index = tempArr[tempArr.length - 1];
        var reader = new FileReader();
        // console.log(target.files[0]);
        reader.readAsDataURL(target.files[0]);
        reader.onloadend = (e) => {
           // console.log(e);
           this.firstSectionData[index].image = e.target.result;
           // console.log(e.target.result);
           this.setState({firstSectionData: this.firstSectionData});
        };
      }
    } else if (name.indexOf('second-section') !== -1) {
      if (name.indexOf('card') !== -1) {
        if (name.indexOf('heading') !== -1) {
          const tempArr = name.split('-');
          const index = parseInt(tempArr[tempArr.length - 1], 10);
          this.secondSectionCards[index].cardHeading = value;
          this.setState({secondSectionCards: this.secondSectionCards});
        } else if (name.indexOf('text') !== -1) {
          const tempArr = name.split('-');
          const index = tempArr[tempArr.length - 1];
          this.secondSectionCards[index].cardText = value;
          this.setState({secondSectionCards: this.secondSectionCards});
        }
      } else {
        if (name.indexOf('heading') !== -1) {
          this.secondSectionData.heading = value;
          this.setState({secondSectionData: {
            heading: this.secondSectionData.heading,
            text: this.state.secondSectionData.text,
            cards: this.state.secondSectionData.cards
          }});
        } else if (name.indexOf('text') !== -1) {
          this.secondSectionData.text = value;
          this.setState({secondSectionData: {
            heading: this.state.secondSectionData.heading,
            text: this.secondSectionData.text,
            cards: this.state.secondSectionData.cards
          }});
        }
      }
    } else if (name.indexOf('tree-block') !== -1) {
      if (name.indexOf('heading') !== -1) {
        const tempArr = name.split('-');
        const index = parseInt(tempArr[tempArr.length - 1], 10);
        this.treeBlocks[index].heading = value;
        this.setState({treeBlocks: this.treeBlocks});
      } else if (name.indexOf('text') !== -1) {
        const tempArr = name.split('-');
        const index = tempArr[tempArr.length - 1];
        this.treeBlocks[index].text = value;
        this.setState({treeBlocks: this.treeBlocks});
      }
    }
    // console.log(this.firstSectionData, this.secondSectionCards, this.treeBlocks);
  }

  handleLanguageChange = event => {
    const target = event.target;
    this.setState({ current_lang: target.value, spinnerShow: true });
    // console.log(target.value, this.languageState);
    if (this.languageState[target.value] !== null) {
      this.setState({row: this.languageState[target.value]});
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!this.state.isAdd) {
        axios.get(`${this.apiEndPoint}/portfolio${target.value}s?id=${this.state.row.id}`, 
          {
            headers: { 'Authorization': `Bearer ${user.jwt}` } 
          }
        ).then(
          (response) => {
            if (response.data.length !== 0) {
              this.languageState[target.value] = response.data[0];
              this.setState({
                spinnerShow: true,
                row: this.languageState[target.value], 
                firstSectionData: response.data[0].first_section,
                treeBlocks: response.data[0].tree,
                secondSectionData: response.data[0].second_section,
                secondSectionCards: response.data[0].second_section.cards }, () => {
                  this.setState({spinnerShow: false})
              });
            } else {
              this.setState({
                spinnerShow: false, 
                list: true, 
                isAdd: true,
                company_image_preview: null,
                portfolio_image_preview: null,
                company_logo_preview: null,
                firstSectionData: null,
                secondSectionData: null,
                secondSectionCards: null,
                treeBlocks: null
              }, () => {
                this.firstSectionData = null;
                this.secondSectionData = null;
                this.secondSectionCards = null;
                this.treeBlocks = null;
                this.portfolioImage = null;
                this.companyImage = null;
                this.companyLogo = null;
                this.getAllPortfolios();
              })
            }
          }
        ).catch(
          (err) => {
            // console.error(err);
          }
        ).finally(
          () => {

          }
        )
      }
    }
  }

  handleLanguageChangeList = event => {
    const target = event.target;
    const value = target.value;
    this.setState({ current_lang: value }, () => {
      this.getAllPortfolios();
    });
  }

  resetForm() {
    if (!this.state.list) {
      for (const props in this.state.row) {
        if (document.getElementsByName(props)[0]) {
          document.getElementsByName(props)[0].value = '';
        }
      }
    }
    this.setState({ row: null });
    this.initialLang = this.state.current_lang;
    this.formDirty = false;
    this.formData = {};
  }

  searchData = event => {
    const target = event.target;
    const value = target.value;
    let searchResult = [];
    if (this.initialData && this.initialData.length !== 0) {
      for (const item of this.initialData) {
        let pushThis = false;
        for (const prop in item) {
          if (prop === 'business' || prop === 'client') {
            if (typeof item[prop] === 'string') {
              if (item[prop].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                pushThis = true;
              }
            }
          }
        }
        if (pushThis) {
          searchResult.push(item);
        }
      }
      this.setState({data: searchResult})
    } else {
      this.setState({data: this.initialData})
    }
  }


/**
   * Handles delete action on selected row.
   */
  handleDelete = row => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (typeof window !== 'undefined') {
      if (window.confirm('Are you sure you want to delete this item?')) {
        axios.delete(`${this.apiEndPoint}/portfolio${this.state.current_lang}s/${row.id}`, {
          headers: { 'Authorization': `Bearer ${user.jwt}` }
        }).then(
          (response) => {
            // console.log(response.data);
          }
        ).catch(
          (err) => {
            // console.error(err);
          }
        ).finally(
          () => {
            this.getAllPortfolios()
          }
        )
      } else {

      }
    }
    // console.log('handle click', row);
  }

  render() {
    const { show, list, data, columns } = this.state
    if (!show) {
      return null;
    } else {
      const { row } = this.state;
      const secondSection = {
        heading: null,
        text: null
      };

      if (row) {
        if (row['second_section']) {
          secondSection.heading = row['second_section'].heading;
          secondSection.text = row['second_section'].text;
        }
      }

      return(
        <div>
          <Spinner show={this.state.spinnerShow}/>
          <div className="admin-content-subheader">
            <div className="admin-subheader-left">
              {list ? (
                  <span className="fa fa-plus-circle admin-add-btn" onClick={this.addPortfolio}></span>
                ) : (
                  <span className="fa fa-caret-left admin-back-btn" onClick={this.goBack}><span className="admin-back-text">Back</span></span>
                )
              }
            </div>
            <div className="admin-subheader-right">
              {list ? (
                  <div>
                    <select className="lang-select" name="current_lang" onChange={this.handleLanguageChangeList}>
                      <option value="en" selected>
                        EN
                      </option>
                      <option value="nl">
                        NL
                      </option>
                    </select>
                    <input placeholder="Search" onChange={this.searchData}/>
                  </div>
                ) : (
                  <div>
                    <select className="lang-select" name="current_lang" onChange={this.handleLanguageChange}>
                      <option value="en" selected>
                        EN
                      </option>
                      <option value="nl">
                        NL
                      </option>
                    </select>
                    <button className="admin-save-button" onClick={() => this.savePortfolio(this)}>Save</button>
                  </div>
                )
              }
            </div>
          </div>
          <div className="admin-content-warpper">
            {list ? (
              <div className="content-list-wrapper custom-table">
                <DataTable
                  title="Portfolio"
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 275px)"
                />
              </div>
            ) : (
              <div className="content-form-wrapper portfolio-admin-wrapper">
                <form>
                  <div className="admin-form-section-3">
                    <div className="admin-input-wrapper">
                      <label className="required">Client</label>
                      <input name="client" onChange={this.handleInputChange}/>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Company name</label>
                      <input name="company_name" onChange={this.handleInputChange}/>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Company email</label>
                      <input name="email" onChange={this.handleInputChange}/>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Business</label>
                      <input name="business" onChange={this.handleInputChange}/>
                      {/*<input className={this.state.valid['summary'] ? '' : 'input-required'} required name="summary" onChange={this.handleInputChange}/>*/}
                    </div>
                    <div className="admin-input-wrapper">
                      <label>Acquired</label>
                        <DatePicker
                          selected={ this.state.aquired }
                          name="aquired"
                          onChange={(date) => this.handleDateChange('aquired', date)}
                          showMonthYearPicker
                          dateFormat="MM/yyyy"
                        />
                    </div>
                    <div className="admin-input-wrapper">
                      <label>Sold</label>
                        <DatePicker
                          selected={ this.state.sold }
                          name="Sold"
                          onChange={(date) => this.handleDateChange('Sold', date)}
                          showMonthYearPicker
                          dateFormat="MM/yyyy"
                        />
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Company employee</label>
                      <input name="company_member" onChange={this.handleInputChange}/>
                      {/*<input className={this.state.valid['summary'] ? '' : 'input-required'} required name="summary" onChange={this.handleInputChange}/>*/}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="">Other investment</label>
                      <select name="company_related" onChange={this.handleInputChange}>
                        {
                          this.state.row ? (
                            this.state.row.company_related || this.state.row.company_related === '--' ? (
                              <option selected>--</option>
                            ):
                            <option>--</option>
                          ):
                          <option selected>--</option>
                        }
                        {
                          this.state.company_related_portfolio.map(
                            (item,index) => 
                              this.state.row ? (
                                this.state.row.company_related || this.state.row.company_related !== '--' ? (
                                  <option value={item.id} selected>{item.client}</option>
                                ): (
                                  <option value={item.id}>{item.client}</option>
                                )
                              ) : (
                                <option value={item.id}>{item.client}</option>
                              )
                          )
                        }
                      </select>
                      {/*<input className={this.state.valid['summary'] ? '' : 'input-required'} required name="summary" onChange={this.handleInputChange}/>*/}
                    </div>
                    <div className="admin-input-wrapper">
                      <label>Active</label>
                      <label className="switch">
                        <input type="checkbox" checked={this.state.isAdd ? (this.state.checkHandler) : (this.state.row ? (this.state.row.active) : (this.state.checkHandler))} name="active" onChange={this.handleInputChange} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="admin-form-section-3">
                    <div className="admin-input-wrapper">
                      <label className="required">Company address</label>
                      <input name="address" onChange={this.handleInputChange}/>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Company phone</label>
                      <input name="phone" onChange={this.handleInputChange}/>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Google maps pin link</label>
                      <input name="maps_pin_link" onChange={this.handleInputChange}/>
                      {/* <input name="client" onChange={this.handleInputChange}/> */}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Slug</label>
                      <input name="slug" onChange={this.handleInputChange}/>
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Company testimony</label>
                      <input name="company_testimony" onChange={this.handleInputChange}/>
                      {/*<input className={this.state.valid['summary'] ? '' : 'input-required'} required name="summary" onChange={this.handleInputChange}/>*/}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Company website</label>
                      <input name="company_website" onChange={this.handleInputChange}/>
                      {/*<input className={this.state.valid['summary'] ? '' : 'input-required'} required name="summary" onChange={this.handleInputChange}/>*/}
                    </div>
                    <div className="admin-input-wrapper">
                      <label className="required">Portfolio categoriy</label>
                      <select name="category" onChange={this.handleInputChange}>
                        <option value="current" selected>
                          Current Investments
                        </option>
                        <option value="divested">
                          Divested
                        </option>
                      </select>
                      
                      {/*<input name="company_website" onChange={this.handleInputChange}/>*/}
                    </div>
                  </div>
                  <div className="admin-form-section-3">
                    <div className="admin-image-upload">
                      <label>Portfolio image</label>
                      <div className="image-preview-wrapper">
                        <img alt="" src={this.state.row !== null && (this.state.row.portfolio_image !== undefined && this.state.row.portfolio_image !== null) ? 
                                        (this.apiEndPoint + '/' + this.state.row.portfolio_image.url) : 
                                        (this.state.portfolio_image_preview !== null ? this.state.portfolio_image_preview : '')}></img>
                      </div>
                      <input className="file-input" name="portfolio_image" type="file" onChange={this.uploadImage}/>
                    </div>
                    <div className="admin-image-upload">
                      <label>Testimony image</label>
                      <div className="image-preview-wrapper">
                        <img alt="" src={this.state.row !== null && (this.state.row.company_image !== undefined && this.state.row.company_image !== null) ? 
                                        (this.apiEndPoint + '/' + this.state.row.company_image.url) : 
                                        (this.state.company_image_preview !== null ? this.state.company_image_preview : '')}></img>
                      </div>
                      <input className="file-input" name="company_image" type="file" onChange={this.uploadImage}/>
                    </div>
                    <div className="admin-image-upload">
                      <label>Logo</label>
                      <div className="image-preview-wrapper">
                        <img alt="" src={this.state.row !== null && (this.state.row.company_logo !== undefined && this.state.row.company_logo !== null) ? 
                                        (this.apiEndPoint + '/' + this.state.row.company_logo.url) : 
                                        (this.state.company_logo_preview !== null ? this.state.company_logo_preview : '')}></img>
                      </div>
                      <input className="file-input" name="company_logo" type="file" onChange={this.uploadImage}/>
                    </div>
                  </div>
                  {
                    /*
                    <div className="admin-editor-wrapper">
                    <label className={this.state.valid['article_content'] ? '' : 'label-required'}>Job description</label>
                    <div className={this.state.valid['article_content'] ? '' : 'editor-required'}>
                      <MdEditor
                        ref={node => this.mdEditor = node}
                        value={this.state.row !== null ? this.state.row.job_text : ''}
                        renderHTML={(text) => this.mdParser.render(text)}
                        onChange={this.handleEditorChange}
                        required
                      />
                    </div> 
                  </div>
                  */
                  }
                </form>
                <div className="clearfix"></div>
                <div className="first-section-wrapper">
                  <h2>First section</h2>
                  <span className="add-section fa fa-plus-circle" onClick={this.addFirstSectionData} title="Add section data"></span>
                  <div className="clearfix"></div>
                    {
                      this.state.firstSectionData.map(
                        (items, index) =>
                          <div>
                            <div key={index.toString()} className="first-section-from">
                              <span className="fa fa-window-close close-button" onClick={() => this.removeFirstSectionData(index)}></span>
                              <form>
                                <div className="admin-form-section-2">
                                  <div className="admin-input-wrapper">
                                    <label>Heading</label>
                                    <input name={`first-section-heading-${index}`} value={items.heading} onChange={this.handleDynamicChange}/>
                                  </div>
                                  <div className="admin-input-wrapper">
                                    <label>Text</label>
                                    <textarea name={`first-section-text-${index}`} value={items.text} onChange={this.handleDynamicChange}/>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                                <div className="admin-form-section-2">
                                  <div className="admin-image-upload">
                                    <label>Image</label>
                                    <div className="image-preview-wrapper">
                                      <img alt="" src={items.image}></img>
                                    </div>
                                    <input type="file" name={`first-section-image-${index}`} onChange={this.handleDynamicChange}/>
                                  </div>  
                                </div>
                                <div className="clearfix"></div>
                              </form>
                              <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          
                      )
                    }
                </div>
                <div className="second-section-wrapper">
                  <h2>Second section</h2>
                  <div className="clearfix"></div>
                  <div className="second-section-form">
                    <form>
                      <div className="admin-form-section-3">
                        <div className="admin-input-wrapper">
                          <label>Heading</label>
                          <input name="second-section-heading" onChange={this.handleDynamicChange} value={secondSection.heading} />
                        </div>
                        <div className="admin-input-wrapper">
                          <label>Text</label>
                          <textarea name="second-section-text" onChange={this.handleDynamicChange} value={secondSection.text} />
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="second-section-cards-wrapper">
                        <button onClick={this.addSecondSectionCards} id="second-section">Add section cards</button>
                        {
                          this.state.secondSectionCards.map(
                            (items, index) =>
                            <div key={index.toString()} className="second-section-cards">
                              <span className="fa fa-window-close close-button" onClick={() => this.removeSecondSectionCards(index)}></span>
                              <div className="admin-input-wrapper">
                                <label>Card heading</label>
                                <input name={`second-section-card-heading-${index}`} value={items.cardHeading} onChange={this.handleDynamicChange}/>
                              </div>
                              <div className="admin-input-wrapper">
                                <label>Card text</label>
                                <textarea name={`second-section-card-text-${index}`} value={items.cardText} onChange={this.handleDynamicChange}/>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          )
                        }
                      </div>
                      <div className="clearfix"></div>
                    </form>
                    <div className="clearfix"></div>
                  </div>
                    {/*
                      this.state.secondSectionData.map(
                        (items, index) =>
                          <div className="second-section-from">
                            <span className="fa fa-window-close close-button" onClick={() => this.removeSecondSectionData(index)}></span>
                            <form>
                              <div className="admin-input-wrapper">
                                <label>Heading</label>
                                <input name={`second-section-heading-${index}`} value={items.heading}/>
                              </div>
                              <div className="admin-input-wrapper">
                                <label>Text</label>
                                <textarea name={`second-section-text-${index}`} value={items.text}/>
                              </div>
                              <div className="admin-input-wrapper">
                                <label>Image</label>
                                <input type="file" name={`second-section-text-${index}`} value={items.text}/>
                              </div>
                              <div className="clearfix"></div>
                            </form>
                            <div className="clearfix"></div>
                          </div>
                      )
                      */}
                </div>
                <div className="portfolio-tree-wrapper">
                  <h2>Tree</h2>
                  <span className="add-section fa fa-plus-circle" onClick={this.addTreeBlock} title="Add tree block"></span>
                  <div className="clearfix"></div>
                  <div className="tree-form-wrapper">
                      {
                        this.state.treeBlocks.map(
                          (items, index) =>
                          <div key={index.toString()} className="tree-block">
                            <span className="fa fa-window-close close-button" onClick={() => this.removeTreeBlock(index)}></span>
                              <div className="admin-input-wrapper">
                                <label>Tree block heading</label>
                                <input name={`tree-block-heading-${index}`} value={items.heading} onChange={this.handleDynamicChange}/>
                              </div>
                              <div className="admin-input-wrapper">
                                <label>Tree block text</label>
                                <textarea name={`tree-block-text-${index}`} value={items.text} onChange={this.handleDynamicChange}/>
                              </div>
                              <div className="clearfix"></div>
                          </div>
                        )
                      }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
  }
}